import React, { useEffect, useState } from 'react';
import './portfolioPage.css';
import headerImage from './img/ltp-c.png';
import storyboardImage from './img/storyboard.png';
import prognosisImage from './img/prognosis.png';
import alignmentImage from './img/alignment.png';
import chartImage from './img/line.png';
import alertImage from './img/alert.png';
import timeImage from './img/time.png';
import impImage from './img/imp.png';
import pieImage from './img/pie.png';
import trendImage from './img/trends.png';
import patientImage from './img/patient.png';
import affinityImage1 from './img/affinity1.png';
import affinityImage2 from './img/affinity2.png';
import ecoImage from './img/ecosystem.png';
import Menu from '../Menu/Menu';
import portfolioData from './portfolioData.json';
import MenuPhone from "./MenuPhone";
import MagnifyingGlass from "./Magn";

function PortfolioPage2() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { tags, duration, tools, sections } = portfolioData;
    const [overlayImage, setOverlayImage] = useState(null);

    const [showMenu, setShowMenu] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const handleImageClick = (imgSrc) => {
        setOverlayImage(imgSrc);
    };
    const closeOverlay = () => {
        setOverlayImage(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Hide or show the menu based on scroll direction
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scroll down
                setShowMenu(false);
            } else {
                // Scroll up
                setShowMenu(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    return (
        <div className="portfolio-page-wrapper">
            <div className={`navigation-container ${showMenu ? 'show' : 'hide'}`}>
                {isMobile ? <MenuPhone /> : <Menu />}
            </div>

            <div className="portfolio-content">
                <div className="portfolio-header">
                    <img src={headerImage} alt="Header" className="portfolio-header-image"/>
                    <div className="portfolio-project-name">
                        Prediction Dashboard for Transplant Recipients
                    </div>
                </div>

                <div className="portfolio-tags-info">
                    <div className="portfolio-tags">
                        {tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
                    </div>
                    <div className="portfolio-description">
                        Medical Dashboard with ML predictions to improve the decision-making process for transplant physicians.
                    </div>
                </div>

                <div className="portfolio-details responsibilities-section">
                    <div className="project-details">
                        <strong>Roles & Responsibilities:</strong> Product Designer, UX Researcher, Frontend Developer<br/>
                        <strong>Duration:</strong> {duration}<br/>
                        <strong>Tools used:</strong> {tools.join(", ")}
                    </div>
                </div>

                <div className="portfolio-details">
                    <h5>Context</h5>
                    <p>Organ transplant recipients often face short-term health challenges post-surgery, such as organ rejection and infection. While clinicians can quickly spot these outcomes, long-term challenges are harder to detect. To predict life-threatening outcomes that might occur years later, clinicians rely on monitoring medical data and identifying suspicious changes over time.</p>
                </div>

                <div className="portfolio-details section-row">
                    <div className="column-text">
                        <h5>Problem</h5>
                        <p>EMR systems collect patient information in general profiles, but specialized hospital departments often struggle to navigate the vast amounts of data, much of which may be irrelevant to their specific needs. This wastes clinicians’ and patients’ time, making it harder for organ transplant practitioners to identify impactful changes in medical data.</p>
                    </div>
                    <div className="column-text">
                        <h5>Solution</h5>
                        <p>A medical dashboard displaying relevant patient information and changes over time would streamline data navigation for medical personnel. When powered by deep learning algorithms, this solution can also predict long-term complications, improving the overall monitoring of transplant recipients' health.</p>
                    </div>
                </div>
                {overlayImage && (
                    <div className="image-overlay" onClick={closeOverlay}>
                        <div className="overlay-content">
                            <img src={overlayImage} alt="Overlay" className="overlay-image" />
                        </div>
                    </div>
                )}

    <div className="portfolio-details bg-white">
                    <h5>Research</h5>
                    <div className="research-section">
                        <div className="section-row">
                            <div className="column-text">
                                <p>The first step was conducting expert interviews and literature reviews to understand
                                    the nuances of organ transplantation recovery. This phase provided a foundational
                                    understanding of the impact every detail of patient’s medical history has on their
                                    life post-surgery. We also learnt how troublesome it is for clinicians to gather
                                    this data and analyze it as a whole.</p>
                            </div>
                            <img src={storyboardImage} alt="Storyboard"
                                 onClick={() => handleImageClick(storyboardImage)} className="clickable-image"/>

                        </div>


                        <p>By shadowing clinicians and nurses, we observed their daily workflows and interactions,
                            gaining insights into practical needs and system deficiencies. These observations were
                            crucial for developing a user-centric design. Using field observations and previous
                            interviews we were able to create an affinity map that showcased all the ideas, concerns and
                            needs of developers and end-users.</p>
                        <div className="affinity-map-container">
                            <img src={affinityImage1} alt="Storyboard"
                                 onClick={() => handleImageClick(affinityImage1)} className="clickable-image"/>
                            <img src={affinityImage2} alt="Storyboard"
                                 onClick={() => handleImageClick(affinityImage2)} className="clickable-image"/>

                        </div>
                        <div className="section-row">
                            <div className="column-text">
                                <p>We analyzed several EMR systems that were used by medical personnel of UHN. This
                                    helped us better understand the existent ecosystem of clinic and how this dashboard
                                    would be
                                    integrated into it.</p>
                            </div>
                            <img src={ecoImage} alt="Storyboard"
                                 onClick={() => handleImageClick(ecoImage)} className="clickable-image"/>
                        </div>
                    </div>
    </div>
                <div className="portfolio-details bg-purple">
                    <h5>Design Decisions</h5>
                    <p>
                        <strong>Pie Chart for Patients:</strong> During shadowing sessions and observing
                        patient-clinician interactions, I
                        noticed an interesting behavioral pattern: 6 out of 10 male patients did not take their
                        treatment
                        plans seriously (often being scolded by their doctors). <br/>
                        1) The CDC reports that women are 33% more likely to visit the doctor than men, and are 100%
                        better
                        at maintaining screenings and preventive care. <br/>
                        2) 65% of organ transplant recipients are males. <br/>
                        Considering these statistics, I suggested implementing a patient-focused data visualization
                        screen
                        to encourage patients to take their doctors’ recommendations more seriously. After discussions
                        with
                        both end-users and the client, this feature was approved for implementation.
                    </p>
                    <img src={pieImage} alt="Pie Chart" className="right-image"/>

                    <p>
                        <strong>Custom Table Alignment:</strong> In feedback sessions, end-users were divided into two
                        groups regarding data
                        table alignment. Senior clinicians, familiar with the older EMR system, preferred Date-Aligned
                        View
                        of the table, while younger practitioners favored Parameter-Aligned View. This “anchoring bias”
                        influenced our decision to make the alignment customizable.
                    </p>
                    <img src={alignmentImage} alt="Align Chart" className="right-image"/>

                    <p>
                        <strong>Line Chart for Data History:</strong> Inspired by weather forecast graphs, we aimed to
                        improve the visualization of data changes over
                        time. Implementing a line chart allowed users to quickly view changes in selected parameters and
                        compare them across time.
                    </p>
                    <img src={chartImage} alt="Chart" className="right-image"/>
                    <p>
                        <strong>Prediction Reliability:</strong> Introducing ML predictions related to human life into
                        clinicians’ workflows must be done cautiously.
                        These predictions should be used as educational tools, not diagnostics. To enforce this, the
                        application reminds users of the tool’s purpose. <br/>

                        <img src={alertImage} alt="Alert" className="right-image"/>

                        Additionally, we needed to provide "proof" of the predictions’ accuracy through Feature
                        Importance.
                        As few clinicians are familiar with ML and Feature Importance, this information had to be as
                        intuitive as possible. We used A/B/C testing and the Think-Aloud protocol to test the clarity of
                        various designs.

                        <img src={impImage} alt="Feature Importance" className="right-image"/>
                    </p>
                </div>

                    <div className="portfolio-details bg-white">
                        <h5>Results</h5>
                        <p>
                            The final prototype was tested by a group of previously uninvolved clinicians, comparing task completion time between the old EMR system and the new prototype.
                        </p>
                        <img src={timeImage} alt="Time Comparison" className="kpi-image1"/>
                        <p>
                            10 participants were asked to complete a task of finding a patient's value for parameter A registered on date B and compare it to the value of parameter A on date C.
                        </p>
                    </div>

                <div className="portfolio-details bg-purple">
                    <h5>Final Thoughts</h5>
                    <p>
                        While challenging to implement, this project helped me build fundamental skills in product
                        design. <br/>
                        1) Relate to the user. <br/>
                        2) Make the circumstances work for you. <br/>
                        3) Take initiative. <br/>
                        I am proud to have been a part of this project and to have learned from the people around me.
                    </p>
                    <img src={patientImage} alt="Patient Pie Chart" className="portfolio-img"/>
                    <img src={trendImage} alt="Data Trends" className="portfolio-img"/>
                    <img src={prognosisImage} alt="Prognosis Chart" className="portfolio-img"/>


                </div>
            </div>
        </div>
    );
}

export default PortfolioPage2;
