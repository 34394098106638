import React from 'react';
import './HomePage.css';
import arrowImageH from './img/arrow1.png'; // Make sure to add the arrow.png image in your project folder

function HomePage() {
    return (
        <div className="HomePage">
            <div className="intro">
                Hi, I’m Anna – a Product Designer with experience in healthcare and game design, crafting safe and engaging spaces for people.
            </div>
            <div className="arrow-containerh">
                <div className="my-work-text">my work</div>
                <img src={arrowImageH} alt="ArrowHome" />
            </div>
        </div>
    );
}

export default HomePage;
