import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './portfoliolist.css';
import intro1 from './img/003.png';
import intro2 from './img/intro22.png';
import { useInView } from 'react-intersection-observer';
import img9 from "./img/proj/final poster.png";

function PortfolioList() {
    const navigate = useNavigate();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0
    });

    const projects = [
        {
            title: 'Pre-Consultation AI Assistant',
            image: intro1,
            tags: [ 'User Research', 'Mobile App','Transparent Data Handling'],
            description: 'LLM powered solution that gives patients control over their data, streamlines patient-clinician communication and reduces wait times at clinics.',
            imageClass: 'intro1',
        },
        {
            title: 'Prediction Dashboard for Transplant Recipients',
            image: intro2,
            tags: ['Complex Data Visualization', 'SaaS', 'WebApp'],
            description: 'Medical Dashboard with ML predictions to educate and improve the decision-making process for transplant physicians.',
            imageClass: 'intro2',
        },
        {
            title: '"Purry!"',
            image: img9,
            tags: ['RPG', 'Unity', 'C#'],
            description: 'Indie role-playing game in pixel art style with engaging adventure in Corporate-themed Purgatory.',
            imageClass: 'img9', // Class for game image
        }
    ];

    const handlePanelClick = async (index) => {
        await new Promise(resolve => setTimeout(resolve, 500));
        if (index === 0) {
            navigate('/chatbot');
        } else if (index === 1) {
            navigate('/dashboard');
        } else {
            navigate('/indie-game');
        }
    };

    return (
        <div ref={ref} className="portfolio-container">
            <div className="other"> <span className="emphasized">  </span></div>
            {projects.map((project, index) => (
                <div key={index} className={`portfolio-panel ${inView ? 'slide-in' : ''}`}
                     style={{ animationDelay: `${index * 1}s` }}
                     onClick={() => handlePanelClick(index)}>
                    <div className="portfolio-image-container">
                        <img src={project.image} alt={project.title} className={`port-image ${project.imageClass || ''}`} />
                    </div>
                    <div className="portfolio-content">
                        <div className="portfolio-title">{project.title}</div>
                        <div className="portfolio-tags">{project.tags.map(tag => <span key={tag} className="tagg">{tag}</span>)}</div>
                        <p className="port-description">{project.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PortfolioList;
