import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './menuPhone.css';
import { FaBars } from "react-icons/fa";

const MenuPhone = ({ onSelected }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const [showContactOptions, setShowContactOptions] = useState(false);

    const handleNavigate = (path) => {
        if (path === 'resume') {
            window.open(process.env.PUBLIC_URL + '/Anna_Kirik_Product_Designer_Resume.pdf', '_blank');
        } else {
            if (path === 'home') {
                window.scrollTo(0, 0);  // Scroll to the top of the page
            }
            onSelected ? onSelected(path) : navigate(pathMapping[path]);
        }
    };

    const pathMapping = {
        home: '/',
        about: '/#about',
        portfolioList: '/#portfolioList',
    };

    const handleEmail = () => {
        window.location.href = "mailto:anna.v.kirik@gmail.com";
    };

    const handleLinkedIn = () => {
        window.open("https://www.linkedin.com/in/anna-kirik/", "_blank");
    };

    const handleGitHub = () => {
        window.open("https://github.com/Annnanke", "_blank");
    };

    return (
        <div className="menu-phone">
            <div className="menu-phone-icon" onClick={() => setShowMenu(!showMenu)}>
                <FaBars />
            </div>
            <div className="menu-phone-header" style={{textAlign: 'center', width: '100%', cursor: 'pointer'}}
                 onClick={() => handleNavigate('home')}>
                Anna Kirik | UX / Product Designer
            </div>
            {showMenu && (
                <div className="menu-phone-buttons">
                    <button onClick={() => handleNavigate('home')}>home</button>
                    <button onClick={() => handleNavigate('about')}>about</button>
                    <button onClick={() => handleNavigate('resume')}>resume</button>
                        {showContactOptions && (
                            <div className="contact-options-phone">
                                <button onClick={handleEmail}>email</button>
                                <button onClick={handleLinkedIn}>linkedin</button>
                                <button onClick={handleGitHub}>github</button>
                            </div>
                        )}
                </div>
            )}
        </div>
    );
};

export default MenuPhone;
