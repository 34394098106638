import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import ReactPlayer from 'react-player';
import './indieGame.css';
import Menu from "../Menu/Menu";
import MenuPhone from "./MenuPhone";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import headerImage from './img/proj/img5.png';

const imageFiles = [
    require('./img/proj/img9.png'),
    require('./img/proj/img5.png'),
    require('./img/proj/img6.png'),
    require('./img/proj/img4.png'),
    require('./img/proj/img13.png'),
    require('./img/proj/img14.png'),
    require('./img/proj/img3.png'),
    require('./img/proj/img7.png'),
    require('./img/proj/img10.png'),
    require('./img/proj/img8.png'),
    require('./img/proj/img1.png'),
    require('./img/proj/img2.png'),
    require('./img/proj/img11.png'),
    require('./img/proj/img12.png'),
    require('./img/proj/cleo-01.png'),
    require('./img/proj/eugene-01-01.png'),
    require('./img/proj/rio-01.png'),
    require('./img/proj/wrath-01.png'),
    require('./img/proj/zina-01.png'),

];

const imageDescriptions = [
    "game logos",
    "game poster",
    "gameplay: dialog system",
    "gameplay: character selection",
    "gameplay: in-game interface ",
    "gameplay: object interaction",
    "location: 'Subway'",
    "location: 'Purger Queen'",
    "location: 'Lobby'",
    "location: 'Gluttony's Office'",
    "location: 'Floor 777'",
    "location: 'Orange Street'",
    "location: 'Elevator'",
    "location: 'Utility Room'",
    "character design: 'Cleo'",
    "character design: 'Eugene'",
    "character design: 'Rio'",
    "character design: 'Wrath'",
    "character design: 'Zina'",

];

const images = imageFiles.map((img, index) => ({
    original: img,
    thumbnail: img,
    description: imageDescriptions[index] // Add description here
}));


function IndieGamePage() {
    const [isMuted, setIsMuted] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const tags = ['RPG', 'Unity', 'C#'];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMute = () => setIsMuted(!isMuted);

    return (
        <div className="portfolio-page-wrapper">
            <div className="fixed-menu">
                {isMobile ? <MenuPhone/> : <Menu/>}
            </div>
            <div className="portfolio-header-indie">
                <img src={headerImage} alt="Game Header" className="portfolio-header-image"/>
                <div className="portfolio-project-name">
                    "Purry!"
                </div>
            </div>
            <div className="portfolio-tags-ind">
                <div className="portfolio-tags">{tags.map(tag => <span key={tag} className="tag">{tag}</span>)}</div>
                <div className="indie-description">
                    Indie role-playing game in pixel art style with engaging adventure in Corporate-themed Purgatory.
                </div>
            </div>
            <div className="portfolio-details bg-white">
                <strong>Roles & Responsibilities:</strong>
                <div className="content-split">
                    <div className="half-column">
                        <h5>Game Designer:</h5>
                        <div className="half-column-par">Developed core gameplay mechanics and level design to ensure a balanced and engaging player
                            experience.</div>
                    </div>
                    <div className="half-column">
                        <h5>Developer:</h5>
                        <div className="half-column-par">Implemented game logic using Unity, integrating physics and user interactions for smooth
                            gameplay.</div>
                    </div>
                </div>
            </div>
            <ReactPlayer
                url="/purry.mp3"
                playing={isPlaying}
                muted={isMuted}
                loop={true}
                width="0"
                height="0"
                volume={0.2}
                onReady={() => console.log('Audio ready to play')}
                onError={() => console.log('Error playing audio')}
            />
            <button className="mute-button" onClick={toggleMute}>
                {isMuted ? <VolumeOffIcon color="inherit"/> : <VolumeUpIcon color="inherit"/>}
            </button>
            <p className="gallery-description">
                You weren't too sinister to get trapped in Hell but also not good enough to chill in Heaven? It's
                okay, because only today you have a chance to enjoy the middle ground of the afterlife - Purgatory!
                Here you can face both the struggle and the joy of being dead! We've created the most average place
                possible for the most average human being like you!
            </p>
            <div className="gallery-container">
                <ImageGallery items={images} showPlayButton={false} showFullscreenButton={true}/>
            </div>

        </div>
    );
}

export default IndieGamePage;
