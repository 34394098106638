import React, { useState, useEffect } from 'react';
import './Menu.css';
import { useNavigate } from 'react-router-dom';
import Asset4 from './svg2/Asset4.svg';
import Asset3 from './svg2/Asset3.svg';
import Asset2 from './svg2/Asset2.svg';
import Asset1 from './svg2/Asset1.svg';
import Right1 from './svg2/right1.svg';
import Right2 from './svg2/right2.svg';
import Left1 from './svg2/left1.svg';
import Left2 from './svg2/left2.svg';

const Menu = () => {
    const navigate = useNavigate();
    const [logoSrc, setLogoSrc] = useState(Asset4);
    const [isHovered, setIsHovered] = useState(false);
    const [hoverTarget, setHoverTarget] = useState('');

    useEffect(() => {
        let animationInterval;

        if (isHovered && hoverTarget === 'logo') {
            const initialAnimation = [Asset3, Asset2, Asset1, Asset1];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(initialAnimation[index]);
                index += 1;
                if (index === initialAnimation.length) {
                    clearInterval(animationInterval);
                    setLogoSrc(Asset1); // Stay on Asset1 as long as hovered
                }
            }, 150);
        } else if (isHovered && hoverTarget === 'contact') {
            const initialAnimation = [Asset4, Right1, Right2];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(initialAnimation[index]);
                index += 1;
                if (index === initialAnimation.length) {
                    clearInterval(animationInterval);
                    setLogoSrc(Right2); // Stay on Right2 as long as hovered
                }
            }, 150);
        } else if (isHovered && hoverTarget === 'about') {
            const initialAnimation = [Asset4, Left1, Left2];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(initialAnimation[index]);
                index += 1;
                if (index === initialAnimation.length) {
                    clearInterval(animationInterval);
                    setLogoSrc(Left2); // Stay on Left2 as long as hovered
                }
            }, 150);
        } else if (hoverTarget === 'contact') {
            const exitAnimation = [Right2, Right1];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(exitAnimation[index]);
                index += 1;
                if (index === exitAnimation.length) {
                    clearInterval(animationInterval);
                    setLogoSrc(Asset4);
                }
            }, 150);
        } else if (hoverTarget === 'about') {
            const exitAnimation = [Left2, Left1];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(exitAnimation[index]);
                index += 1;
                if (index === exitAnimation.length) {
                    clearInterval(animationInterval);
                    setLogoSrc(Asset4);
                }
            }, 150);
        } else if (hoverTarget === '') {
            const exitAnimation = [Asset1, Asset2, Asset3, Asset4, Asset4, Asset4];
            let index = 0;

            animationInterval = setInterval(() => {
                setLogoSrc(exitAnimation[index]);
                index += 1;
                if (index === exitAnimation.length) {
                    clearInterval(animationInterval);
                }
            }, 150);
        }

        return () => clearInterval(animationInterval);
    }, [isHovered, hoverTarget]);

    const handleHover = (target) => {
        setHoverTarget(target);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setHoverTarget('');
    };

    return (
        <div className="menu">
            <div
                className="logo"
                onClick={() => navigate('/')}
                onMouseEnter={() => handleHover('logo')}
                onMouseLeave={handleMouseLeave}
            >
                <img src={logoSrc} alt="Logo" style={{ cursor: 'pointer' }} />
            </div>
            <div className="contact-button">
                <button
                    onClick={() => window.open('/Anna_Kirik_Product_Designer_Resume.pdf', '_blank')}
                    onMouseEnter={() => handleHover('contact')}
                    onMouseLeave={handleMouseLeave}
                >
                    resume
                </button>
            </div>
            <div className="about-button">
                <button
                    onClick={() => navigate('/about')}
                    onMouseEnter={() => handleHover('about')}
                    onMouseLeave={handleMouseLeave}
                >
                    about
                </button>
            </div>
        </div>
    );
};

export default Menu;
