import React, { useEffect, useState } from 'react';
import './portfolioPage.css';
import headerImage from './img/cbot.png';
import chatImage from './img/DocReady/chat.png';
import summaryImage from './img/DocReady/summary.png';
import summaryEditImage from './img/DocReady/summaryEdit.png';
import finImage from './img/DocReady/clinics.png';
import fin1Image from './img/DocReady/home.png';
import editChoiceImage from './img/DocReady/editChoice.png';
import empathy2 from './img/empathy2.png';
import empathy1 from './img/empathy1.png';
import avgWaitTimeImage from './img/DocReady/Average wait time.png';
import susImage from './img/DocReady/SUS.png';
import Menu from '../Menu/Menu';
import MenuPhone from './MenuPhone';
import 'react-image-gallery/styles/css/image-gallery.css';

function PortfolioPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const tags = ['User Research', 'Mobile App', 'Transparent Data Handling'];

    const [showMenu, setShowMenu] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    // Hide or show the menu based on scroll direction
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scroll down
                setShowMenu(false);
            } else {
                // Scroll up
                setShowMenu(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="portfolio-page-wrapper">
            {/* Add the navigation panel */}
            <div className={`navigation-container ${showMenu ? 'show' : 'hide'}`}>
                {isMobile ? <MenuPhone /> : <Menu />}
            </div>

            <div className="portfolio-content">
                <div className="portfolio-header">
                    <img src={headerImage} alt="Header" className="portfolio-header-image"/>
                    <div className="portfolio-project-name">
                        Pre-Consultation AI Assistant
                    </div>
                </div>
                <div className="portfolio-tags-info">
                    <div className="portfolio-tags">{tags.map(tag => <span key={tag}
                                                                           className="tag">{tag}</span>)}</div>
                    <div className="portfolio-description">
                        LLM powered solution that gives patients control over their data, streamlines patient-clinician
                        communication and reduces wait times at clinics.
                    </div>
                </div>
                    <div className="portfolio-details responsibilities-section">
                    <div className="project-details">
                        <strong>Roles & Responsibilities:</strong> Product Designer, UX Researcher, Frontend Developer<br/>
                        <strong>Duration:</strong> 11 months<br/>
                        <strong>Tools used:</strong> Figma, FigJam, Qualtrics, LucidChart, MURAL, Calendly <br/>
                    </div>
                </div>

                <div className="portfolio-details">
                    <h5>Context</h5>
                    <p>In primary healthcare, long patient wait times and inefficient pre-consultation processes often
                        hinder the quality of care and patient experience. Particularly, pre-consultation
                        assessments—the process of collecting relevant medical information before a patient meets the
                        clinician—lack automation due to staff shortages, worsening the issue.</p>
                </div>

                <div className="portfolio-details">
                    <div className="section-row">
                        <div className="column-text">
                            <h5>Problem:</h5>
                            <p>At the time of this product’s implementation, no patient-focused system addresses issues
                                like medical personnel shortages, long wait times, and low patient engagement and agency
                                in their healthcare, resulting in overall dissatisfaction.</p>
                        </div>
                        <div className="column-text">
                            <h5>Solution:</h5>
                            <p>An app with general walk-in clinic navigation and an AI-powered chatbot to collect
                                pre-consultation information would address these issues.</p>
                        </div>
                    </div>
                </div>

                <div className="portfolio-details">
                    <h5>Research</h5>
                    <ul>
                        <li>
                            <strong>Empathy Mapping </strong>
                            <p>Empathy maps for two types of end-users—patients and clinicians—helped grasp the
                                emotional and practical needs of both groups during the pre-consultation phase.</p>
                        </li>
                        <div className="empathy-map-container">
                            <img src={empathy1} alt="Empathy Map 1" className="empathy-map-image"/>
                            <img src={empathy2} alt="Empathy Map 2" className="empathy-map-image"/>
                        </div>
                        <li>
                            <strong>Competitor Analysis</strong>
                            <p>Key findings from our competitor analysis include:</p>
                            <ol className="custom-list">
                                <li>Most applications with similar functionalities are Telehealth platforms.</li>
                                <li>Telemedicine applications often lack transparency in data usage and do not offer
                                    patients control over their information.
                                </li>
                                <li>No applications are currently used for pre-consultation purposes in real-life
                                    primary care facilities as of this product's launch.
                                </li>
                            </ol>
                        </li>
                        <li>
                            <strong>Relevant Literature Review</strong>
                            <p>Relevant research provided insights into users’ preferences when conversing with
                                chatbots, which was crucial for the chatbot creation process.</p>
                        </li>
                    </ul>
                    <div className="principles-box">
                        <strong>Key Principles for Medical Chatbots</strong>
                        <ul>
                            <li>1. Personalization: Responses should be tailored to the user's context. <br/><span
                                className="small-text">Beyond Self-diagnosis: How a Chatbot-based Symptom Checker Should Respond, 2021</span>
                            </li>
                            <li>2. Transparency: Explain decision-making processes clearly. <br/><span
                                className="small-text">Patients’ Utilization and Perception of an Artificial Intelligence–Based Symptom Assessment and Advice Technology in a British Primary Care Waiting Room, 2020</span>
                            </li>
                            <li>3. Empathy: Use supportive and empathetic language. <br/><span className="small-text">Patient-Reported Outcome+ Platform for Remote Symptom Management, 2021</span>
                            </li>
                            <li>4. Adaptive Flow: Ask dynamic questions based on user input. <br/><span
                                className="small-text">Patient-Reported Outcome+ Platform for Remote Symptom Management, 2021</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="portfolio-details">
                    <h5>Design Decisions</h5>
                    <ul>
                        <li>
                            <strong>Summary Review</strong>
                            <p>Applications incorporating pre-consultation assessments don’t allow patients to see the
                                generated medical summary, limiting their control over the data used by clinicians. To
                                address this, a summary review step was included in the chatbot-patient conversation,
                                enhancing data transparency and patient engagement.</p>
                            {/* Add the images in a two-column layout */}
                            <div className="design-images-container">
                                <img src={chatImage} alt="Chat" className="design-image"/>
                                <img src={summaryImage} alt="Summary" className="design-image"/>
                            </div>
                        </li>
                        <li>
                            <strong>Summary Format: Original Quotes vs. Medically Interpreted</strong>
                            <p>Usability testing revealed that a percentage of participants preferred summaries
                                containing their original quotes, highlighting the importance of transparent data
                                handling in medical processes.</p>
                        </li>
                        <div className="triangle-structure">
                            <div className="triangle-top">
                                <strong>Patient's Quote</strong>
                                <p>"I've been feeling really tired all the time for the past month, and sometimes I get
                                    dizzy when I stand up. I also get headaches every now and then."</p>
                            </div>
                            <div className="triangle-bottom">
                                <div className="summary-box simple-summary">
                                    <strong>Simple Language Summary</strong>
                                    <p>The patient has been experiencing constant tiredness for a month, occasional
                                        dizziness when standing up, and occasional headaches.</p>
                                </div>
                                <div className="summary-box">
                                    <strong>Medically Translated Summary</strong>
                                    <p>The patient reports experiencing chronic fatigue for one month, episodes of
                                        orthostatic dizziness, and intermittent cephalalgia.</p>
                                </div>
                            </div>
                        </div>
                        <li>
                            <strong>Editing Feature</strong>
                            <p>To prevent misinterpretations, the summary review and editing features were introduced,
                                allowing patients to make necessary changes before finalizing their summary.</p>
                            {/* Add the images in a two-column layout */}
                            <div className="design-images-container">
                                <img src={editChoiceImage} alt="Edit Choice" className="design-image"/>
                                <img src={summaryEditImage} alt="Summary Edit" className="design-image"/>

                            </div>
                        </li>
                    </ul>
                </div>

                <div className="portfolio-details">
                    <h5>Evaluation</h5>
                    <ul>
                        <li><strong>Wait Time + Patients Treated Daily:</strong> The average wait time with and without
                            the DocReady app helps evaluate the impact on staff shortages and long wait times.
                            <img src={avgWaitTimeImage} alt="Average Wait Time" className="kpi-image1"/>
                        </li>
                        <li><strong>System Usability Scale (SUS):</strong> This is used to evaluate the general
                            usability of the app and end-user satisfaction.
                            <img src={susImage} alt="SUS" className="kpi-image"/>
                        </li>
                    </ul>
                </div>

                <div className="portfolio-details">
                    <h5>Final Thoughts</h5>
                    <p>This project was significant to me as I was the sole designer on the team. I'm proud of myself
                        for:</p>
                    <ul className="final-thoughts-list">
                        <li>Embracing mistakes and learning from them.</li>
                        <li>Advocating for my design choices and user needs.</li>
                        <li>Staying involved in the development process and adjusting designs when needed for easier
                            implementation.
                        </li>
                    </ul>


                    <p>This was an inspiring project that improved daily processes I could relate to and see firsthand.
                        I also appreciated the valuable interviews, usability tests, and user studies conducted.</p>

                    <div className="design-images-container">
                        <img src={finImage} alt="clinics" className="design-image"/>
                        <img src={fin1Image} alt="home" className="design-image"/>

                    </div>
                </div>


            </div>
        </div>
    )
        ;
}

export default PortfolioPage;
