import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import './App.css';
import HomePage from './components/Pages/HomePage';
import AboutPage from './components/Pages/About/AboutPage';
import PortfolioList from './components/Pages/PortfolioList';
import PortfolioPage from './components/Pages/PortfolioPage';
import PortfolioPage2 from './components/Pages/PortfolioPage2';
import Menu from './components/Menu/Menu';
import MenuPhone from './components/Pages/MenuPhone';
import { FaLinkedin, FaGithub, FaGoogle } from 'react-icons/fa';
import IndieGamePage from './components/Pages/IndieGamePage';

function useMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
}

function MainContent() {
    const location = useLocation();
    const isMobile = useMobile();
    const [showMenu, setShowMenu] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const scrollTo = (section) => {
        scroller.scrollTo(section, {
            duration: 200,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -50,
        });
    };

    // Hide or show the menu based on scroll direction
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scroll down
                setShowMenu(false);
            } else {
                // Scroll up
                setShowMenu(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    useEffect(() => {
        if (location.state && location.state.scrollToSection) {
            setTimeout(() => {
                scrollTo(location.state.scrollToSection);
            }, 100);
        }
    }, [location]);

    return (
        <>
            <div className={`navigation-container ${showMenu ? 'show' : 'hide'}`}>
                {isMobile ? <MenuPhone onSelected={scrollTo}/> : <Menu onSelected={scrollTo}/>}
            </div>
            <Element name="home"><HomePage/></Element>
            <Element name="portfolioList"><PortfolioList/></Element>
        </>
    );
}

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<MainContent/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/chatbot" element={<PortfolioPage/>}/>
                    <Route path="/dashboard" element={<PortfolioPage2/>}/>
                    <Route path="/indie-game" element={<IndieGamePage/>}/>
                </Routes>
                <div className="bottom-panel">
                    <div className="icons">
                        <a href="https://www.linkedin.com/in/anna-kirik/" className="icon" target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>
                        <a href="https://github.com/Annnanke" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub/></a>
                        <a href="mailto:anna.v.kirik@gmail.com" className="icon"><FaGoogle/></a>
                    </div>
                    <div className="copyright">
                        © 2024 by Anna Kirik. All rights reserved.
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
