import React from 'react';
import Menu from '../../Menu/Menu';
import './aboutPage.css';
import profileImage from '../img/me1.png';

function AboutPage() {
    return (
        <div className="about-page-container">
            <div className="navigation-container">
                <Menu />
            </div>

            <div className="content-container">
                <div className="profile-and-text">
                    <div className="profile-section">
                        <img src={profileImage} alt="Profile" className="profile-picture" />
                    </div>

                    <div className="text-section">
                        <p className="paragraph-text">
                            My name is <span className="highlight">Anna</span>. I'm a <span className="highlight">Product Designer </span>
                             with a passion for using my healthcare and gaming background to craft adaptable, user-friendly designs.
                        </p>
                        <p className="paragraph-text">
                            My approach combines <span className="highlight">deep compassion</span> for end-users, <span
                            className="highlight">stress-resistant adaptability</span>, and <span className="highlight">clear communication</span> to align business and user needs effectively.
                        </p>
                    </div>
                </div>

                <div className="info-section">
                    <div className="info-column">
                        <h3>Hobbies</h3>
                        <p>🏄‍♀️ Surfing</p>
                        <p>🧚 D&D</p>
                        <p>🎭 Improv</p>
                    </div>

                    <div className="info-column">
                        <h3>Places</h3>
                        <p>📍 Based in Toronto</p>
                        <p>🇺🇦 Originally from Ukraine</p>
                    </div>
                    <div className="info-column">
                        <h3>Education</h3>
                        <p>🖥 Bachelor of Software Engineering</p>
                        <p>🧑‍🎓 National University of Kyiv-Mohyla Academy</p>
                        <p>🎒 University of Toronto</p>
                    </div>
                    <div className="info-column">
                        <h3>Contact</h3>
                        <p><a href="mailto:anna.v.kirik@gmail.com" target="_blank" rel="noopener noreferrer">📨 Gmail</a>
                        </p>
                        <p><a href="https://www.linkedin.com/in/anna-kirik/" target="_blank" rel="noopener noreferrer">🌐
                            LinkedIn</a></p>
                        <p><a href="/Anna_Kirik_Product_Designer_Resume.pdf" target="_blank" rel="noopener noreferrer">📄
                            Resume</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
